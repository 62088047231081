@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'muli-regular';
  src: url('/public/fonts/Muli.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-bold';
  src: url('/public/fonts/Muli-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-semibold';
  src: url('/public/fonts/Muli-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-black';
  src: url('/public/fonts/Muli-Black.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.font-black {
  font-family: 'muli-black';
}

.font-semibold {
  font-family: 'muli-semibold';
}

.font-bold {
  font-family: 'muli-bold';
} */

body {
  /* font-family: 'muli-regular'; */
  font-family: 'Inter', sans-serif;
}

.intialPos {
  position: initial !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.font-kalam{
  font-family: 'Inter', sans-serif !important;
}
.fil-gr{
  filter: grayscale(1);
}

.sec-1:before{
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  content: '';
  opacity: 0.56;
  top: 50%;
  background: linear-gradient(90deg, #3BE4B7 0%, #6EB4D7 100%);
  filter: blur(99px);
}

.sec .inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sec .inner .img-wrap{
  width: calc(100% - 650px);
  position: relative;
}
.pattern{
  position: relative;
}
.logo-wrap img{
  width: 40px;
}
.sec .inner .img-wrap:before, .pattern:before{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  content: '';
  opacity: 0.56;
  top: 0;
  background: linear-gradient(90deg, #3BE4B7 0%, #6EB4D7 100%);
  filter: blur(99px);
  z-index: 1;
}

.pattern:before{
  top: -60px;
  opacity: 0.32;
background: linear-gradient(270deg, #3BE4B7 0%, #6EB4D7 100%);
filter: blur(50px);
}
.sec-1 .desk-img{
  border-radius: 10px 0 0 10px;
}
.sec-4 .box, .sec-7 .box, .sec-5 .box{
  border-radius: 16px;
  background: #FFF;
  padding: 32px 15px;
  box-shadow: 0px 24px 64px 0px rgba(24, 24, 27, 0.04);
}
.sec-5 .box img{
  height: 326px;
}
.sec-5{
  position: relative;
}
.sec-5:before{
  position: absolute;
  content: '';
  width: 100%;
  max-width: 944px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 565px;
  opacity: 0.1;
  background: linear-gradient(90deg, #3BE4B7 0%, #6EB4D7 100%);
  filter: blur(99px);
}

.sec-7 .box{
  padding: 48px 20px;
}

.bg-gradient{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.sec-5 .img-wrap{
  border-radius: 8px;
  background: var(--grdnt, linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%));
}

.sec-5 .img-wrap img{
  mix-blend-mode: multiply;
}


footer .inner{
  display: flex;
  justify-content: space-between;
}

footer .inner .left p{
  margin-top: 24px;
}

footer .inner .links-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 90px;
}

footer .inner .links-wrapper .wrap span{
  color: #6A6A77; 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px; /* 170% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 26px;
}


footer .inner .left p, footer .inner .links-wrapper ul li a, footer .copyright p, footer .copyright a{
  color: #4D4D56;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.8px; /* 170% */
  letter-spacing: -0.09px;
}

footer .inner .links-wrapper ul li{
  margin-bottom: 20px;
}

footer .copyright{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  border-top: 1px solid #ECECEE;
  margin-top: 60px;
}

footer .copyright span{
  color: #18181B;
  font-weight: 500;
}

.popup-wrap{
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.profile-wrap{
  margin-top: 80px;
}

.profile-wrap h2, .statistics-chart-wrapper h2{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

.profile-wrap .boxes{
  display: flex;
  margin: 0 -15px 60px;
  flex-wrap: wrap;
}

.profile-wrap .boxes .box{
  width: calc(25% - 30px);
  margin: 0 15px 30px;
  text-align: center;
  border-radius: 5px;
background: #FFF;
padding: 32px;
box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
}


.profile-wrap .boxes .box img{
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.profile-wrap .boxes .box h2{
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 18px;
}

.profile-wrap .boxes .box p{
  color: #A3A3A3;
font-size: 16px;
letter-spacing: 0.042px;

}

/* Statistics */
.statistics-chart-wrapper {
  margin-top: 150px;
}

.statistics-chart-wrapper .chart-wrap{
  border-radius: 32px;
  background: rgb(231 251 249);
  padding: 32px 32px;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-yaxis-label,
.statistics-chart-wrapper .chart-wrap .apexcharts-xaxis-label{
  color: #27313B;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-title-text{
  color: #27313B;
  font-size: 24px;
  font-weight: 600;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-toolbar{
  display: none;
}

.course-chart-info{
  display: flex;
  margin: 6px -24px -12px;
}

.course-chart-info .info{
  width: calc(33.33% - 48px);
  margin: 12px 24px;
  border-radius: 100px;
  background: #18181b;
  text-align: center;
  padding: 24px 12px;
}

.course-chart-info .info p{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
}

.profile-link{
  display: flex;
  height: 56px;
  width: 150px;
  align-items: center;
  justify-content: center;
}
.filewrap{
  position: relative;
}

.filewrap input{
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
  position: absolute;
}

.filewrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.filewrap span{
  color: #a0a7b2;
}

.filewrap .upload-btn{
  background: transparent;
  border: 1px solid #272A33;
  border-radius: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
}

.filewrap .upload-btn img{
  margin-right: 5px;
}

.popup-wrap #veriff-root{
  max-width: 100%;
}

.popup-wrap h2{
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 4px;
}

 .popup-wrap p{
  font-size: 1rem;
  line-height: 1.3rem;
}

#veriff-root input{
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 1.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-radius: 0.75rem;
  background-color: rgb(245 245 245);
  box-shadow: none;
  border-color: #e5e7eb;
}

#veriff-root input[type="submit"]{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  cursor: pointer;
}

#veriff-root  .veriff-description a{
  font-size: 15px;
}

#veriff-root  .veriff-description{
  margin: 0;
}
@media(min-width:992px) {
.ty-50{
  transform: translateY(-50%);
}
}
@media(max-width:1350px) {
  .sec-1 .desk-img{
    max-width: 550px !important;
  }
}
@media(max-width:1199px) {
  .sec .inner .img-wrap{
    width: 45%;
  }

  .sec .inner .content{
    width: 52% !important;
  }

  .sec-1 .desk-img{
    max-width: 40% !important;
    top: 50%;
    margin-top: 100px;
  }
  .profile-wrap .boxes .box{
    width: calc(33.33% - 30px);
  }
  .profile-wrap .boxes .box img{
    height: 210px;
  }
}

@media(max-width:992px) {
  .customDate[type="date"]::-webkit-calendar-picker-indicator {
    top: 1rem !important;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.customDate[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../public/images/calender.svg') no-repeat;
  width: 14px;
  height: 16px;
  border-width: thin;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}


.custom-radio-wrap form .form-group label {
  appearance: none;
  background-color: white;
  border: 2px solid #262B42;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.custom-radio-wrap form .form-group .label-text {
  vertical-align: middle;
  cursor: pointer;
  padding-left: 10px;
}

.custom-radio-wrap form .form-group input {
  display: none;
  cursor: pointer;
}

.custom-radio-wrap form .form-group input:checked+label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 2px solid #D82E35;
}

.custom-radio-wrap form .form-group input:checked+label:after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #D82E35;
  box-shadow: inset 0px 0px 10px #D82E35;
  text-shadow: none;
  font-size: 32px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  display: none;
}
@media(max-width:991px) {
  .profile-wrap .boxes .box img {
    height: 170px;
  }
  .sec-1 .desk-img{
    top: 44%;
    margin-top: 0;
  }
}
@media(max-width:767px) {
  
  .sec-1:before{
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .sec .inner .img-wrap{
    width: 100%;
    margin-bottom: 30px;
  }

  .sec .inner .content{
    width: 100% !important;
    max-width: 100% !important;
  }

  .sec.sec-3 .inner, .sec.sec-6 .inner {
    flex-direction: column-reverse;
  }

  .sec-7 .box{
    padding: 32px 15px;
  }

  .profile-wrap .boxes .box {
    width: calc(50% - 30px);
  }
  .profile-wrap .boxes .box img {
    height: 200px;
  }
}
@media(max-width:575px) {
  footer .inner{
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    justify-content: flex-start;
  }

  footer .copyright{
    flex-direction: column;
    gap: 20px;
  }
  .profile-wrap .boxes .box {
    width: 100%
  }
  .profile-wrap .boxes .box img {
    height: auto;
  }
}