.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999;
  }
  .loader .loader-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loader .loader-area img{
    width: 100px;
    display: block;
    margin: 0 auto;
  }
  .loader .loader-area .css-1mxr1bq,
  .loader .loader-area .css-8vx899,
  .loader .loader-area .css-anak8p {
    width: 80px;
    height: 80px;
  }
  
  .loader .loader-area  .loading-text {
    color: #888;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
  }
  
  .loader .loader-area .css-1i5m00m .css-m5syh7,
  .loader .loader-area .css-1i5m00m .css-8q43r4 {
    background-color: #55cbc6;
    height: 70px;
    width: 70px;
  }
  